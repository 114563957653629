import type ModelCollectionTournament  from "./model/ModelCollectionTournament"
import type Tournament  from "./model/Types/Tournament"


/*
    содержит
    tournamentsOpen: Array<Tournament>    
    tournamentsInPlay: Array<Tournament> 
    notifyOpenCount:number

    как пользоваться: 

    notifyOpenCount выводишь в navbar
    
    выводишь  tournamentsOpen в окно  турниры ближайшие
        
        при отображении проверяешь 
        если tournament.isPlayerInPlay( playerUuid ) - то мы участвуем в этом турнире
        если tournament.isJoinableByPlayer( playerUuid ) && tournament.isRatingOk(playerRating) - то можем записаться на турнир                
            isJoinableByPlayer проверяет есть ли свободное место в турнире и что мы еще не записаны на него          
            isRatingOk  проверяет условие рейтинга 
            
    выводишь tournamentsInPlay в идущие            
*/

export default class ArraySortedTournament {
    tournamentsAll:ModelCollectionTournament

    tournamentsOpen: Array<Tournament>    
    tournamentsInPlay: Array<Tournament>      

    tournamentsCanceled: Array<Tournament>    
    tournamentsFinished: Array<Tournament>     
    notifyOpenCount:number    
    notifyInPlayCount:number 

    //TournamentsAnnounced: Array<Tournament>
    
    constructor(Tournaments:ModelCollectionTournament) {
        this.tournamentsAll = Tournaments;
        this.tournamentsOpen = [];
        //this.TournamentsAnnounced  = [];
        this.tournamentsInPlay  = [];
        this.tournamentsCanceled = [];
        this.tournamentsFinished = []; 
        this.notifyOpenCount = 0;
        this.notifyInPlayCount = 0;
    }

    clear() {
        this.tournamentsOpen = [];
        //this.TournamentsAnnounced  = [];
        this.tournamentsInPlay  = [];  
        this.notifyOpenCount = 0;
        this.notifyInPlayCount = 0;   
    }

    update(tournaments:ModelCollectionTournament, playerUuid:string) {
        this.clear();        
        tournaments.forEachAsync( (tournament) => {                                
            if(tournament.isOpen()) 
                this.tournamentsOpen.push(tournament);                

            if(tournament.isInPlay()) 
                this.tournamentsInPlay.push(tournament);                                            
        });        

        this.notifyOpenCount = this.tournamentsOpen.length; 
        this.notifyInPlayCount = this.tournamentsInPlay.length; 
    }     



    printout( log: (...args:Array<any>)=>void ) {
        //log("TournamentsActiveAll");    TournamentsSorted.printTournamentCollection(log, this.TournamentsActiveAll);
        log("tournamentsOpen:");       this.printArray(log, this.tournamentsOpen);        
        log("tournamentsInPlay:");     this.printArray(log, this.tournamentsInPlay);        
    }

    printArray( log: (...args:Array<any>)=>void , models:Array<Tournament>) {

        models.forEach( (tournament) => {
            this.printTournament(log, tournament);
        });        
    }

    printTournament( log: (...args:Array<any>) => void, tournament:Tournament) {
        log("Tournament "+tournament.uuid+" status = "+tournament.status+" ");
    }

}