// https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
import { Middleware } from '@nuxt/types'

import Vue from 'vue'
import logger from '~/modules/utility/logger'
import Game from '~/modules/model/Types/Game';
import { ModuleState } from '~/store/game';
import { RootState } from '~/store';
//import { Store } from 'vuex'



const authCheck: Middleware = async ({store,app,redirect,route}) => { 

    let redirectLock = false;

    function redirectNoDouble(url:string, params:any) {
        if(redirectLock)
            return;

        redirectLock = true;
        setTimeout( ()=> {
            redirectLock = false;            
        });                            
    }

    function redirectTologin() {
        let url = '/login'
        let params = undefined;
        logger.log("Middleware auth redirect to ", url, params);
        redirect(app.localePath(url), params);
    }

    function onLoggedInChanged() {        
        if(!store.state.loggedIn)        
            redirectTologin();
    }
       
    if(process.env.ENABLE_LOGIN_WINDOW == '1') {
        store.watch( (state:RootState, getters) => state.loggedIn,
        () => onLoggedInChanged());        
         onLoggedInChanged();        
    }
    
}

export default authCheck;