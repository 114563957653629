import type ModelCollectionTrade  from "./model/ModelCollectionTrade"
import TradeOffer, {ETradeOfferTypeId}  from "./model/Types/TradeOffer"
import Currency, {ECurrencyType, ECurrencyAbbreviation}  from "./model/Types/Currency"
//import { TRACE } from "js-logger"

/*
    содержит
    tradesGift:       Array<TradeOffer>     
    tradesBuyCoin:    Array<TradeOffer>    
    tradesBuyDesk:    Array<TradeOffer>    
    tradesBuyChips:   Array<TradeOffer>    
    tradesRepearKits: Array<TradeOffer>

    notifyCount:number

    как пользоваться: 

    notifyCount выводишь в navbar
    
    выводишь  tradesGift в окно подарка
        проверить доступно ли можно так
        let serverTimestamp = modelListTrade.getTimestamp();
        lists.tradesGift.forEach(trade => {
            let available = trade.isTimeAvailable(serverTimestamp);
            console.log("Gift "+trade.uuid+" avalaible = "+available); 
        });

    выводишь  tradesBuyCoin   в окно покупки монет
    выводишь  tradesBuyDesk   в окно покупки досок
    выводишь  tradesBuyChips  в окно покупки фишек    

    для раздела "мои покупки" см в ArraySortedAccount.ts
*/

export default class ArraySortedTrade {
    tradesAll:ModelCollectionTrade

    tradesGift:              Array<TradeOffer>  
    tradesBuyCoin:           Array<TradeOffer>    
    tradesBuyDesk:           Array<TradeOffer>    
    tradesBuyChips:          Array<TradeOffer>
    tradesGiftAvailable:     Array<TradeOffer> 
    tradesRepearKits:        Array<TradeOffer> 

    
    notifyCount:number    

    //TournamentsAnnounced: Array<Tournament>
    
    constructor(trades:ModelCollectionTrade) {
        this.tradesAll = trades;
        this.notifyCount = 0;
        this.tradesGift = [];    
        this.tradesBuyCoin = [];
        this.tradesBuyDesk = [];
        this.tradesBuyChips = [];
        this.tradesGiftAvailable = [];
        this.tradesRepearKits = [];
    }

    clear() {
        this.notifyCount = 0;
        this.tradesGift = [];         
        this.tradesBuyCoin = [];
        this.tradesBuyDesk = [];
        this.tradesBuyChips = [];
        this.tradesGiftAvailable = [];
        this.tradesRepearKits = [];     
    }

    update(trades:ModelCollectionTrade, serverTimestamp:number) {
        this.clear();      
        trades.forEachAsync( (tradeOrig) => { 
            let trade = new TradeOffer(tradeOrig);
            trade.isAvailable = trade.isTimeAvailable(serverTimestamp);
            
            if(trade.isGift()) 
                this.tradesGift.push(trade);

            if( trade.isCoinsBuy() ) {
                this.tradesBuyCoin.push(trade);
            }

            if(trade.isSystemItem())  {
                let currencyTo = trade.currencyTo;
                if(currencyTo.type_id == ECurrencyType.CHIPS)
                    this.tradesBuyChips.push(trade);

                if(currencyTo.type_id == ECurrencyType.DESK)
                    this.tradesBuyDesk.push(trade);
            }  

            if (trade.isRepearKit()) 
                this.tradesRepearKits.push(trade);

        });        

        this.tradesBuyCoin.sort( ( a : TradeOffer, b : TradeOffer ) => {
            if ( a.value_to > 0 && b.value_to > 0 ) {
                return a.value_to - b.value_to
            }
            return 0;
        })

        function sortItems( a : TradeOffer, b : TradeOffer ): number {
            if (a.currencyFrom.code_name !== b.currencyFrom.code_name) {
                if (a.currencyFrom.code_name === ECurrencyAbbreviation.GOLD) {
                    return 1
                }
                else {
                    if (b.currencyFrom.code_name === ECurrencyAbbreviation.GOLD) {
                        return -1
                    }
                }
            }
            if ( a.value_from > 0 && b.value_from > 0 ) {
                return a.value_from  - b.value_from 
            }
            return 0;
        }

        this.tradesBuyChips.sort(sortItems);
        this.tradesBuyDesk.sort(sortItems);

        this.tradesGiftAvailable = this.tradesGift.filter(gift => gift.isAvailable);

        this.notifyCount = this.tradesGift.reduce( (value, gift) => value + (gift.isTimeAvailable(serverTimestamp)? 1:0), 0);

    }     



    printout( log: (...args:Array<any>)=>void ) {        
        log("tradesGift:");               this.printArray(log, this.tradesGift);
        log("tradesBuyCoin:");            this.printArray(log, this.tradesBuyCoin);
        log("tradesBuyChips:");           this.printArray(log, this.tradesBuyChips);                        
        log("tradesBuyDesk:");            this.printArray(log, this.tradesBuyDesk);                       
        log("tradesRepearKits:");         this.printArray(log, this.tradesRepearKits); 
    }

    printArray( log: (...args:Array<any>)=>void , models:Array<TradeOffer>) {
        models.forEach( (tournament) => {
            this.printTrade(log, tournament);
        });        
    }

    printTrade( log: (...args:Array<any>) => void, trade:TradeOffer) {
        log("Trade "+trade.uuid+" type string = "+trade.typeString+" ");
    }

}