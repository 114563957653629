import TransportInterface from "./transport/TransportInterface"
import UserConnector from "./connectors/UserConnector"
import TopConnector from "./connectors/TopConnector"
import { NuxtAxiosInstance } from "@nuxtjs/axios"
import TransportAxios from "./transport/TransportAxios"
import logger from "./utility/logger"
import io, { Socket } from 'socket.io-client'
import ModelListGame from "./ModelListGame"
import ModelListPlayer from "./ModelListPlayer"
import ModelListAccount from "./ModelListAccount"
import ModelListTournament from "./ModelListTournament"
import ModelListTrade from "./ModelListTrade"
import ModelListTop from "./ModelListTop"
import TradeConnector from "./connectors/TradeConnector"
import EventEmitter from 'eventemitter3'
import SocketConnector from "./connectors/SocketConnector"
//import { TilingSprite } from "~/static/client/app"
import TradeConnectorRobokassa from "./robokassa/TradeConnectorRobokassa"
import { sharedStorageSession } from "./SharedStorage"
import SupportConnector from "./connectors/SupportConnector"


const SESSION_STORE_NAME = process.env.SESSION_STORE_NAME
export default class BgServer extends EventEmitter {    
    socket!:SocketIOClient.Socket 

    connectorTop!:TopConnector   
    connectorUser!:UserConnector      
    connectorTrade!:TradeConnector
    connectorSupport!:SupportConnector
    
    modelTop!:ModelListTop
    modelPlayer!:ModelListPlayer
    modelGame!:ModelListGame
    modelAccount!:ModelListAccount
    modelTournament!:ModelListTournament
    modelTrade!:ModelListTrade
    axios:NuxtAxiosInstance

    pendingReqestsCount:number

    authPendig: boolean
    

    static readonly EVENT_ONLINE = "online"    
    static readonly EVENT_SESSION_CHANGED = "session_changed"
    static readonly EVENT_PENDING_CHANGED = "pending_changed"    
    static readonly EVENT_AUTH_PENDING_CHANGED = "auth_pending_changed"

    constructor(axios: NuxtAxiosInstance) {
        super();
        this.axios = axios;    
        this.pendingReqestsCount = 0;      
        this.authPendig = false;             
    }

    async init() {  
        this.socket = this.initSocket();                                
        this.connectorUser   = this.initUserConnector(this.axios)
        this.connectorTrade  = this.initConnectorTrade()  
        this.connectorSupport = this.initConnectorSupport();
        this.connectorTop = this.initConnectorTop();      

        this.modelGame       = new ModelListGame(this.connectorUser);
        this.modelAccount    = new ModelListAccount(this.connectorUser);
        this.modelTournament = new ModelListTournament(this.connectorUser);
        this.modelPlayer     = new ModelListPlayer(this.connectorUser);       
        this.modelTrade      = new ModelListTrade(this.connectorUser, this.connectorTrade);
        this.modelTop       = new ModelListTop(this.connectorUser, this.connectorTop);   
        
        this.handlePendingEventsForAll();

        await this.restoreSessionId(); // after this.socket 
    }
    
    initSocket() {
        const SOCKET_HOST = process.env.SOCKET_HOST_DOMAIN? process.env.SOCKET_HOST_DOMAIN : ""
        const SOCKET_PATH = process.env.SOCKET_HOST_PATH? process.env.SOCKET_HOST_PATH : ""

        
        let socket = io(SOCKET_HOST, {
            path: SOCKET_PATH,
            autoConnect: true,                                
        });      
        
        socket.on("connect", ()=>{
            this.updateConnnectionStatus();                     
        })
        socket.on( "disconnect", ()=>{   
            this.doPendingReset();
            this.updateConnnectionStatus();                     
        })     
        
        this.startTimerConnectionStatus();
        return socket;
    }

    timerConnectionStatus: NodeJS.Timeout| null = null
    startTimerConnectionStatus() {
        // На всякий случай раз в 5 секунд проверяем
        // Верясия socket.io 2.3.0 почемуто не переподключала - версия 2.4.1 норм
        // тоесть в общем то этот хак уже не нужен но на всякий случай оставлю его
        this.stopTimerConnectionStatus();        
        this.timerConnectionStatus = setInterval( ()=>this.updateConnnectionStatus(), 5000) 
    }
    stopTimerConnectionStatus() {
        if(!this.timerConnectionStatus)
            return;
        clearInterval(this.timerConnectionStatus)
        this.timerConnectionStatus = null;
    }
    
    setAuthPending(value:boolean) {
        this.authPendig = value;
        this.emit(BgServer.EVENT_AUTH_PENDING_CHANGED, this.authPendig)
    }

    async doAuthroizationAndRetry( f:()=>Promise<any>, period:number = 5000, isRetry: boolean = false) : Promise<void> {
        if(this.authPendig && !isRetry)
            return;// already in progress

        try {
            this.setAuthPending(true)
            await f();
            this.setAuthPending(false)
        } catch(err) {
            //server frequently responds with NETWORK_ERROR on first connection attempts, but Yandex folks want to NEVER see any errors in console (they shouldn't even look there, though):
            //console.error(err)
            console.log( "Network request failed:", err )

            setTimeout( ()=>{
                this.doAuthroizationAndRetry(f, period, true)
            }, period)
        }
    }

    isOnline() {
        return this.socket && this.socket.connected;
    }
    
    updateConnnectionStatus() {        
        let online = this.isOnline()
        this.emit(BgServer.EVENT_ONLINE, online);        
        if(!online && this.socket) {
            this.socket.close();
            this.socket.open();
        }        
    }

    private handlePendingEventsForAll() {
        this.handlePendingEvents(this.connectorUser);
        this.handlePendingEvents(this.modelPlayer.getConnector());
        this.handlePendingEvents(this.modelGame.getConnector());
        this.handlePendingEvents(this.modelTournament.getConnector());        
        this.handlePendingEvents(this.modelTrade.getConnector());
        this.handlePendingEvents(this.modelAccount.getConnector());    
        this.handlePendingEvents(this.modelTop.getConnector());  
    }

    handlePendingEvents(connector:SocketConnector) {

        connector.on(SocketConnector.EMIT_NAME_PENDING_START, ()=>{
            this.changePendingRequestsCount(+1)
        })

        connector.on(SocketConnector.EMIT_NAME_PENDING_STOP, ()=>{       
            this.changePendingRequestsCount(-1)            
        })        
    }

    isPending() {
        return this.pendingReqestsCount > 0
    }

    pendingLimitTimer: NodeJS.Timeout | null = null;

    changePendingRequestsCount(delta:number) {
        let isPendingBefore = this.isPending();                            
        
        this.pendingReqestsCount+=delta;      
        if(this.pendingReqestsCount < 0) {
            logger.warn("Pending requesst less then 0!!!", this.pendingReqestsCount);
            this.pendingReqestsCount = 0;
        }

        let isPendingAfter = this.isPending();     
                
        if(isPendingBefore != isPendingAfter)
            this.emit(BgServer.EVENT_PENDING_CHANGED, isPendingAfter)   
         
        this.timerPendingLimiterUpdate(delta);
    }

    doPendingReset() {
        logger.warn("Reset pending operations!");
        this.changePendingRequestsCount(-this.pendingReqestsCount);
    }

    timerPendingLimiterUpdate(delta:number) {
        const TIMER_PENDING_LIMIT_MS = 5000;
        if(this.pendingReqestsCount > 0) {
            this.timerPendingLimiterSet( ()=>{                
                // TODO show window - oops operation hung 
                // pres wait or reset 
                this.doPendingReset();                
            }, TIMER_PENDING_LIMIT_MS);
        } else {
            this.timerPendingLimiterClear();
        }
    }
    
    timerPendingLimiterClear() {
        if(!this.pendingLimitTimer) 
            return; 
        clearTimeout(this.pendingLimitTimer)
        this.pendingLimitTimer = null;            
    }

    timerPendingLimiterSet( fun: ()=>void, interval:number) {            
        this.timerPendingLimiterClear();                        
        this.pendingLimitTimer = setTimeout(()=>{
            this.pendingLimitTimer = null;
            fun();
        }, interval)
    }
    

    async saveSessionId(sessionId:string) {
        if(!SESSION_STORE_NAME)
            return;
        
        sharedStorageSession.set( SESSION_STORE_NAME, sessionId )
    }

    async restoreSessionId(){
        if(!SESSION_STORE_NAME)
            return;
        
        const sessionId = sharedStorageSession.get( SESSION_STORE_NAME )
        if(!sessionId)
            return;

        this.connectorUser.setSessionId(sessionId);
    }

    getSessionId() {
        return this.connectorUser.sessionId;
    }
    initUserConnector(axios: NuxtAxiosInstance) {
        let transport = new TransportAxios(axios, {
            baseURL: process.env.SERVER_API_URL_BASE
        });
        
        let connectorUser = this.createUserConnector(transport);
        //new UserConnector(this.socket, transport);        
        connectorUser.verbose = process.env.DEBUG_VERBOSE == "true"
        connectorUser.on(UserConnector.EVENT_SESSION_TOKEN_CHANGED, (sessionId?:string) => {            
            if(sessionId === undefined)
                return            
            this.emit( BgServer.EVENT_SESSION_CHANGED, sessionId);                                   
            this.saveSessionId(sessionId);                                     
        })
        connectorUser.on(UserConnector.EVENT_SESSION_NEED_CHECK, (currentSessionId?:string) => {                                    
            this.restoreSessionId();
        })
        return connectorUser                                             
    }

    createUserConnector(transport:TransportAxios) {
        return new UserConnector(this.socket, transport);       
    }

    initConnectorTop() {                        
        const connector : TopConnector = new TopConnector(this.socket)
        connector.verbose = process.env.DEBUG_VERBOSE == "true"
        return connector       
    }

    initConnectorTrade() {                        
        const connector : TradeConnector = new TradeConnectorRobokassa(this.socket)
        connector.verbose = process.env.DEBUG_VERBOSE == "true"
        return connector       
    }

    initConnectorSupport() {
        const connector : SupportConnector = new SupportConnector(this.socket)
        connector.verbose = process.env.DEBUG_VERBOSE == "true"
        return connector
    }
  
    restartConnection() {
        this.socket.close().open();        
    }
        

    onLoadFinish() { 

    }

    async showFullscreenAd() : Promise<boolean | undefined > {
        return undefined
    }
    async showRewardedVideo() : Promise<boolean | undefined > {
        return undefined
    }

    changeAccount(data:any) {
        this.emit('changeAccountMain', data)
    }

    async doRegisterEmail(email: string, code: string|null) {}
    async doChangeRegistrationEmail(email: string, code: string|null) {}
    async doRestorePlayerAccount(email: string, code: string|null) {}
}