// https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
import { Middleware } from '@nuxt/types'

import Vue from 'vue'
import logger from '~/modules/utility/logger'
import Game from '~/modules/model/Types/Game';
import { ModuleState } from '~/store/game';
import { RootState } from '~/store';




const gameListener: Middleware = async ({store,app,redirect,route}) => { 

    let redirectLock = false;

    function redirectNoDouble(url:string, params:any) {
        if(redirectLock)
            return;

        redirectLock = true;
        setTimeout( ()=> {
            redirectLock = false;
            logger.log("Middleware Redirect to ", url, params);
            redirect(app.localePath(url), params);
        });                            

    }
            
    function CheckMyGame(gamesMy:Game[]) {                    
        if (!gamesMy.length || redirectLock)
            return false;
        
        let game = gamesMy[0];
        logger.log("Found my game");     
        redirectNoDouble('/gameActive', {gameUuid: game.uuid})    
        return true;                   
    }

    function CheckMyJoinable(games:Game[]) {                                
        if (!games.length || redirectLock){
            return false;                        
        }
        logger.log("Found joinable game");            
        let game = games[0]; 
        redirectNoDouble('/matchmaking', {gameUuid: game.uuid})        
        return true;                   
    }

    function CheckRevengeForMe(games:Game[]) {                                
        if (!games.length || redirectLock)
            return false;                        

        logger.log("Found revenge for me");
        let game = games[0]; 
        redirectNoDouble('/gameRevengeIncoming', {gameUuid: game.uuid})        
         //TODO        
         return true;                   
     }

    function Update() {
        let gameModule = <ModuleState>store.state.game;
        if( CheckMyGame(gameModule.gamesActiveMy))
            return;

        if(CheckMyJoinable(gameModule.gamesJoinableMy))
            return;

         if(CheckRevengeForMe(gameModule.gamesRevengeForMe))
             return;
    }

    // CHECK ACTIVE GAMES
    
    store.watch( (state, getters) => state.game.gamesActiveMy,
    (newState, oldState) => {
        let check = newState.length > 0;
        if (newState.length > 0 && oldState.length > 0) {
            check = newState[0].uuid !== oldState[0].uuid
        }
        if (check) {
            Update()
        }
    },{deep:true}
    );    
    // CHECK WAIT PLAYER GAMES        
    store.watch( (state, getters) => state.game.gamesJoinableMy,
    (newState, oldState) => {
        let check = newState.length > 0;
        if (newState.length > 0 && oldState.length > 0) {
            check = newState[0].uuid !== oldState[0].uuid
        }
        if (check) {
            Update()
        }
    }, {deep:true}
    ); 

    // store.watch( (state, getters) => state.game.gamesRevengeMy,
    //     () => Update(), {deep:true}
    // );
    
    // store.watch( (state, getters) => state.game.gamesRevengeForMe,
    //     () => Update(), {deep:true}
    // ); 

    Update();    

    // TODO
    // CHECK REVENGE GAMES                      
}

export default gameListener;