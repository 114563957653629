import Player from "./model/Types/Player";
import { IUserInfo } from "./connectors/SocketConnector";


export default class ArraySortedPlayer {

    player?:     Player
    isAdmin:     boolean
    loggedIn:  boolean
    
                
    constructor() {    
        this.isAdmin = false;
        this.loggedIn = false;
        this.player = undefined;
    }

    clear() {                
        this.isAdmin = false;
        this.loggedIn = false;
        this.player = undefined;
    }
        

    update(data:IUserInfo) {    
        this.player = (data.player) ? new Player(data.player): undefined
        this.isAdmin = data.isAdmin
        this.loggedIn = data.logined
    }

}