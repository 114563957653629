import type ModelCollectionAccount  from "./model/ModelCollectionAccount"
import type Account from "./model/Types/Account";
import Currency, {ECurrencyType}  from "./model/Types/Currency"

/*
 содержит
    accountMain?:    Account        // главный счет
    accountRating?:  Account        // рейтинг
    accountsItem:   Array<Account>  // покупки      
    accountsMoney:  Array<Account>  // все деньги     
    
    как пользоваться: 
        
    выводишь  accountMain  в навбар - там где выводится текущий счет        
    выводишь  accountsItem в окно магазина в раздел "мои покупки"        
*/

export default class ArraySortedAccount {

    accountsAll:     ModelCollectionAccount
    accountMain?:    Account
    accountRating?:  Account
    accountsItem:   Array<Account>        
    accountsMoney:  Array<Account>    

                
    constructor(accounts:ModelCollectionAccount) {
        this.accountsAll = accounts;
        this.accountMain = undefined;
        this.accountRating = undefined;
           
        this.accountsItem  = [];
        this.accountsMoney = [];                                     
    }

    clear() {
        this.accountMain = undefined;
        this.accountRating = undefined;           
        this.accountsItem = [];
        this.accountsMoney = [];      
    }

    update(accounts:ModelCollectionAccount) {
        this.clear();        
        accounts.forEachAsync( (account) => {                                
            if(account.currency.isMoney()) 
                this.accountsMoney.push(account);

            if(account.currency.isItem()) 
                {
                    this.accountsItem.push(account);
                }

            this.accountsItem.sort( ( a : Account, b : Account ) => {
                if ( a.value !== b.value )
                    return b.value - a.value;
                return 0;
            })

            if(account.isSelectedByPlayer)
                this.accountMain = account;

            if(account.currency.isRating())
                this.accountRating = account;            
        });                
    }     

    printout( log: (...args:Array<any>)=>void ) {        
        log("accountMain:");       this.printModel(log, this.accountMain);
        log("accountRating:");     this.printModel(log, this.accountRating);
        log("accountsItem:");      this.printArray(log, this.accountsItem);                       
        log("accountsMoney:");     this.printArray(log, this.accountsMoney);
    }

    printArray( log: (...args:Array<any>)=>void , models:Array<Account>) {
        models.forEach( (model) => {
            this.printModel(log, model);
        });        
    }

    printModel( log: (...args:Array<any>) => void, model?:Account) {
        if(!model)
            log("empty")
        else 
            log("Account "+model.uuid+" value with currency = "+model.getStringValue() +" ");
    }

}